import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import propTypes from "prop-types";
import * as Yup from "yup";
import { API } from "../../../modules/Api";
import Logger from "../../../modules/Logger";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";

class PasswordRecovery extends PureComponent {
  state = {
    recoveryStep1: false,
    emailPasswordRecovery: "",
  };
  ResetPasswordSchema = () => {
    const { t: _t } = this.props;
    return Yup.object().shape({
      email: Yup.string().required(_t("Обязательное поле")).email(_t("Некорректный e-mail")),
    });
  };
  resetPassword = ({ email }) => {
    API.post("user/reset_password", { email }).then(
      (response) => {
        Logger.info(response);
        this.setState({
          recoveryStep1: !this.state.recoveryStep1,
          emailPasswordRecovery: email,
        });
        // this.props.onClose();
      },
      (error) => {
        Logger.info(error);
      },
    );
  };
  render() {
    const { t: _t } = this.props;
    const { recoveryStep1, emailPasswordRecovery } = this.state;
    return (
      <div className="loginPage__form loginPage__form_recovery">
        <div className="loginPage__title">{_t("Восстановление пароля")}</div>
        {recoveryStep1 === false ? (
          <div className="loginPage__formik">
            <p className="loginPage__instruction" style={{ textAlign: "center" }}>
              {_t(
                "Введите адрес электронной почты, который использовался для регистрации вашей учетной записи, и система отправит вам новый пароль на этот адрес электронной почты.",
              )}
            </p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={this.ResetPasswordSchema()}
              onSubmit={this.resetPassword}
            >
              {({ errors }) => (
                <Form className="loginPage__formikInner">
                  <Field name="email">
                    {({ field }) => (
                      <div className="loginPage__input">
                        <p className="loginPage__label">{_t("Email адрес")}</p>
                        <TextField
                          type="string"
                          label="Email адрес"
                          margin="dense"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email}
                          {...field}
                        />
                      </div>
                    )}
                  </Field>
                  <Button type="submit" className="loginPage__btn">
                    {_t("Изменить")}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="formik">
            <div className="checkEmail">
              <p className="checkEmail-text bold">{_t("Мы отправили вам письмо с новым паролем")}</p>
              <p className="checkEmail-email">{emailPasswordRecovery}</p>
              <div className="checkEmail-text-block">
                <p className="checkEmail-text">{_t("Если вы не получили письмо, на всякий случай проверьте:")}</p>
                <li className="checkEmail-text-li">{_t("наличние ошибок в электронном адресе")}</li>
                <li className="checkEmail-text-li">{_t("письмо не попало в папку Спам")}</li>
              </div>
              <p className="checkEmail-text">
                {_t(
                  "Если у вас есть какие-либо вопросы относительно регистрации или доступа к вашей личной странице, напишите на {{address}}, мы все поймем и обязательно поможем.",
                  { address: "meshalign@gmail.com" },
                )}
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

PasswordRecovery.propTypes = {
  t: propTypes.func.isRequired,
};

export default withTranslation()(PasswordRecovery);
