import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { TextField, Button } from "@material-ui/core";
import PasswordRecovery from "./PasswordRecovery";
import { login, obtainLoginError } from "../../redux/actions";
import CloseIcon from "@material-ui/icons/Close";
import "./index.scss";
import PasswordInput from "../../components/PasswordInput/PasswordInput";

function LoginPage() {
  const { t: _t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { loginErrors } = useSelector(({ login }) => ({
    loginErrors: login.errors,
  }));

  const LogInSchema = () => {
    return Yup.object().shape({
      user_name: Yup.string().required(_t("Обязательное поле")),
      password: Yup.string().required(_t("Обязательное поле")),
    });
  };

  function signIn({ user_name, password }) {
    dispatch(
      login(user_name, password, () => {
        history.push("/profile");
      }),
    );
  }

  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false);
  function toggleRecoveryPassword() {
    setIsPasswordRecovery((prev) => !prev);
  }

  function resetFormStatus() {
    if (loginErrors) {
      dispatch(obtainLoginError(null));
    }
  }

  return (
    <div className="loginPage">
      <Button className="loginPage__close" component={Link} to="/" variant="outlined">
        <CloseIcon />
        <span>{_t("Закрыть")}</span>
      </Button>
      {isPasswordRecovery ? (
        <PasswordRecovery />
      ) : (
        <div className="loginPage__form">
          <div className="loginPage__title">{_t("Авторизация")}</div>
          <div className="loginPage__formik">
            <Formik
              initialValues={{
                user_name: "",
                password: "",
              }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={LogInSchema}
              onSubmit={signIn}
            >
              {({ errors, touched }) => (
                <Form className="loginPage__formikInner" onChange={resetFormStatus}>
                  <Field name="user_name">
                    {({ field }) => (
                      <div className="loginPage__input">
                        <p className="loginPage__label">{_t("Логин пользователя")}</p>
                        <TextField
                          type="string"
                          label={_t("Логин пользователя")}
                          margin="dense"
                          fullWidth
                          error={!!errors.email}
                          helperText={errors.email}
                          {...field}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="password">
                    {({ field }) => (
                      <div className="loginPage__input">
                        <p className="loginPage__label">{_t("Пароль")}</p>
                        <PasswordInput
                          errors={errors}
                          touched={touched}
                          fullWidth
                          name="password"
                          label={_t("Пароль")}
                          margin="dense"
                          {...field}
                        />
                      </div>
                    )}
                  </Field>

                  <div style={{ maxWidth: 300, textAlign: "center", margin: "10px auto 0", color: "red" }}>
                    {loginErrors && <div>{_t("Неправильный логин или пароль!")}</div>}
                  </div>

                  <Button type="submit" className="loginPage__btn">
                    {_t("Вход")}
                  </Button>

                  <p className="forgot" onClick={toggleRecoveryPassword}>
                    {_t("Забыли логин или пароль?")}
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginPage;
